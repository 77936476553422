@import 'fonts';

// COLORS
$color-yellow: #FFC306;
$color-accent: #FF4E00;
$color-black: #000000;
$color-white: #ffffff;
$color-grey-lighter: #EBEBEB;
$color-grey-light: #CEC6C0;
$color-grey: #474751;
$color-inner-input: #E7E8DC;
$color-shadow-alpha: rgba(0,0,0,0.29);
$color-red: #BF3325;
$color-green: #B7BF10;
$color-blue: #2F95E4;
$color-purple: #9B7793;

$color-error: #D82E2E;
$color-success: #239D58;
$color-bg-black: #01070D;

// pb colors
$color-pb-first: #FF4E0010;
$color-pb-second: #FF4E0050;

// SPACES
$space-4xs: 1px;
$space-3xs: 2px;
$space-xxs: 4px;
$space-xs: 8px;
$space-sm: 16px;
$space-md: 32px;
$space-lg: 64px;
$space-xl: 128px;
$space-xxl: 256px;

// FONT-SIZE
$fs-xxs: 10px;
$fs-xs: 12px;
$fs-sm: 14px;
$fs-md: 16px;
$fs-xl: 24px;
$fs-xxl: 32px;
$fs-xxxl: 64px;
$fs-xxxxl: 92px;
$fs-modal-title-faq: 22px;
$fs-modal-title: 24px;
$fs-onboarding-title: 32px;

// FONT FAMILY
$ff-black: "Sofia Pro Black";
$ff-bold: "Sofia Pro Bold";
$ff-semi-bold: "Sofia Pro Semi Bold";
$ff-regular: "Sofia Pro Regular";
$ff-medium: "Sofia Pro Medium";

// Others
$border-radius: 8px;
$border-radius-mid: 10px;
$border-radius-rounded: 16px;
$border-radius-max-rounded: 30px;

$team-shield: 40px;

$icon-width: 24px;
$icon-big: 32px;

$avatar-width-default: 38px;
$file-preview-default: 42px;

$shadow-default: 0px 3px 6px $color-shadow-alpha;
$soft-shadow: hsl(0deg 0% 0% / 0.075);

$height-back-arrow: 50px;
$icon-small: 16px;

$max-heigh-select-dropdown: 300px;

$logo-max-width: 119px;

$eval-risk-indicents-options-width: 500px;


// ngx-material-timepicker
*{
    --body-background-color: #fff;
    --primary-font-family: 'Roboto',sans-serif;
    --button-color: #FFC306 !important;
    --dial-active-color: #fff;
    --dial-inactive-color: rgba(255, 255, 255, .5);
    --dial-background-color: #FFC306 !important;
    --dial-editable-active-color: #FFC306 !important;
    --dial-editable-background-color: #fff;
    --clock-face-time-active-color: #fff;
    --clock-face-time-inactive-color: #6c6c6c;
    --clock-face-inner-time-inactive-color: #929292;
    --clock-face-time-disabled-color: #c5c5c5;
    --disabled-options: #c5c5c5;
    --clock-face-background-color: #f0f0f0;
    --clock-hand-color: #FFC306 !important;
}

// medals
$medal-width: 64px;
$medal-completed-width: 32px;
$medal-type-width: 32px;
$medal-team-width: 24px;