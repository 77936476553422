@import 'variables';

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: $space-sm;
    label{
        color: $color-grey;
        font-size: $fs-md;
        margin-bottom: $space-xs;
        line-height: 1;
    }

    textarea, .inputfile, input:not(.searcher){
        border-radius: $border-radius;
        padding: $space-sm;
        font-size: $fs-sm;
        background-color: $color-inner-input;
        outline: none;
        border: 0;
        color: $color-grey;
        box-shadow: 0pt 3pt 6pt $color-shadow-alpha;
    }
    .inputfile{
        min-height: $space-md;
        padding: $space-xxs $space-sm;
        border-radius: $space-md;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 140px;
        input{
            position: absolute;
            top: 0;
            left: 0;
            max-width: 100%;
            text-align: center;
            opacity: 0;
        }
    }

    .input-help{
        margin-top: $space-xs;
        margin-left: $space-xs;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $color-grey-light;
        opacity: 1; /* Firefox */
    }

}



.button-row{
    margin-top: $space-md;
}

// button {
//     border-radius: $border-radius-rounded;
//     box-shadow: $shadow-default;
//     border: 0;
//     padding: $space-xs $space-sm;
//     text-shadow: $shadow-default;
//     &.accent{
//         color: $color-white;
//         font-family: $ff-bold;
//         background-color: $color-accent;
//     }

//     &.normal-size{
//         max-width: 100%;
//         width: 200px;
//     }
// }