@font-face {
    font-family: "Sofia Pro Black Italic";
    src: url("../fonts/Sofia_Pro_Black_Italic.otf");
}

// BLACK
@font-face {
    font-family: "Sofia Pro Black";
    src: url("../fonts/Sofia_Pro_Black.otf");
}

@font-face {
    font-family: "Sofia Pro Bold Italic";
    src: url("../fonts/Sofia_Pro_Bold_Italic.otf");
}


// BOLD
@font-face {
    font-family: "Sofia Pro Bold";
    src: url("../fonts/Sofia_Pro_Bold.otf");
}

// SEMI BOLD
@font-face {
    font-family: "Sofia Pro Semi Bold";
    src: url("../fonts/Sofia_Pro_Semi_Bold.otf");
}

// REGULAR
@font-face {
    font-family: "Sofia Pro Regular";
    src: url("../fonts/Sofia_Pro_Regular.otf");
}

// REGULAR
@font-face {
    font-family: "Sofia Pro Medium";
    src: url("../fonts/Sofia_Pro_Medium.otf");
}