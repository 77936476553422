@import 'variables';
@import 'forms';

* {
    font-family: $ff-regular;
}
html, body{
    height: 100%;
}


*{
    --color-accent: #FF4E00;
    --body-background-color: #fff;
    --primary-font-family: 'Roboto',sans-serif;
    --button-color: var(--color-accent) !important;
    --dial-active-color: #fff;
    --dial-inactive-color: rgba(255, 255, 255, .5);
    --dial-background-color: var(--color-accent) !important;
    --dial-editable-active-color: var(--color-accent) !important;
    --dial-editable-background-color: #fff;
    --clock-face-time-active-color: #fff;
    --clock-face-time-inactive-color: #6c6c6c;
    --clock-face-inner-time-inactive-color: #929292;
    --clock-face-time-disabled-color: #c5c5c5;
    --clock-face-background-color: #f0f0f0;
    --clock-hand-color: var(--color-accent) !important;
}

p, span, .color-black {
    color: $color-black;
}

.color-grey{
    color: $color-grey;
}

.color-red{
    color: $color-red;
}

.ferro-logo-big-wrapper{
    width: 100%;
    img{
        max-width: 100%;
    }
}

.d-flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.mt{
    &-xs{
        margin-top: $space-xs;
    }
    &-sm{
        margin-top: $space-sm;
    }
    &-md{
        margin-top: $space-md;
    }
    &-lg{
        margin-top: $space-lg;
    }
}

.pt{
    &-xs{
        padding-top: $space-xs;
    }
    &-sm{
        padding-top: $space-sm;
    }
    &-md{
        padding-top: $space-md;
    }
}

.mb{
    &-xs{
        margin-bottom: $space-xs;
    }
    &-sm{
        margin-bottom: $space-sm;
    }
    &-md{
        margin-bottom: $space-md;
    }
}

.pb{
    &-xs{
        padding-bottom: $space-xs;
    }
    &-sm{
        padding-bottom: $space-sm;
    }
    &-md{
        padding-bottom: $space-md;
    }
}

.ml{
    &-xs{
        margin-left: $space-xs;
    }
    &-sm{
        margin-left: $space-sm;
    }
    &-md{
        margin-left: $space-md;
    }
}

.pl{
    &-xs{
        padding-left: $space-xs;
    }
    &-sm{
        padding-left: $space-sm;
    }
    &-md{
        padding-left: $space-md;
    }
}

.mr{
    &-xs{
        margin-right: $space-xs;
    }
    &-sm{
        margin-right: $space-sm;
    }
    &-md{
        margin-right: $space-md;
    }
}

.pr{
    &-xs{
        padding-right: $space-xs;
    }
    &-sm{
        padding-right: $space-sm;
    }
    &-md{
        padding-right: $space-md;
    }
}

.small{
    font-size: $fs-xs;
}
.error{
    color: $color-error;
}
.bold{
    font-family: $ff-bold;
}
.semi-bold{
    font-family: $ff-semi-bold;
}

.white-modal{
    position: relative;
    padding: $space-md;
    border-radius: $space-sm;
    background-color: $color-white;
    box-shadow: $shadow-default;

    &.opacity{
        background-color: rgba($color-white, 0.9);
    }

    &.delimited-section-modal{
        padding: $space-sm 0;
        .bordered-section{
            padding: $space-sm $space-md;
            border-bottom: 1px solid $color-grey-light;
        }
        .delimited-section{
            padding: $space-sm $space-md;
        }
    }

    .section{
        margin-bottom: $space-md;
    }
    .white-modal__title{
        font-size: $fs-modal-title;
        color: $color-black;
        font-family: $ff-bold;
    }
    .white-modal__sub-title{
        font-size: $fs-sm;
        color: $color-grey;
    }

    .separator{
        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: $color-grey-light;
            left: 0;
        }
    }
}

.page-with-top-bar{
    padding-top: $space-lg;
}

.full-height{
    height: 100%;
}

.arrow-back{
    width: $height-back-arrow;
    height: $height-back-arrow;
    background-image: url('/assets/images/icons/flecha-naranja.svg');
    background-position: center;
    background-size: cover;
}
  
.icon-small{
    width: $icon-small;
}
.icon-normal{
    width: $icon-width;
}
.green-overlay{
    &::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $color-accent;
        opacity: 0.8;
        top: 0;
        left: 0;
    }
}

.success-snackbar {
    .mdc-snackbar {
        &__surface {
            background: $color-accent !important;
        }
        &__label {
            color: $color-black !important;
        }
    }
    .mdc-button {
        &__label {
            font-family: $ff-bold;
        }
    }
}

.error-snackbar {
    .mdc-snackbar {
        &__surface {
            background: $color-error !important;
        }
        &__action {
            span {
                color: $color-white;
            }
        }
    }
    .mdc-button {
        &__label {
            font-family: $ff-bold;
        }
    }
}

app-top-menu{
    position: fixed;
    width: 100%;
    background-color: rgb(255 255 255 / 86%);
    z-index: 1000;
}

.two-cols-desktop{
    display: flex;
    flex-wrap: wrap;
    .column{
        flex: 0 0 50%;
    }
}

.pointer{
    cursor: pointer;
}
.container.pt-deskt-lg{
    padding-top: $space-lg;
}

.futball-field{
    background-image: url('/assets/images/futball-field.png');
    padding-bottom: $space-lg;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: auto;
    &.field{
        background-image: url('/assets/images/futball-field.png');
    }
}

.futball-field-ball{
    background-color: $color-bg-black;
    background-image: url('/assets/images/futball-field-ball.png');
    padding-bottom: $space-lg;
    background-position: bottom center;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: auto;
}

.pb-ball {
    position: absolute;
    background-image: url('/assets/images/icons/pelota.svg');
    background-size: contain;
    background-repeat: no-repeat;
    height: $space-md;
    width: $space-md;
    top: - #{$space-xs};
}

.no-scroll-y { overflow-y: hidden; }


// Angular innerHtml does not get the component styles but styles here work
.faq-category-question-answer {
    table {
        width: 100%;
        text-align: center;
    }
    .faq-table-colored {
        table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
        }
        .colored-yellow {
            background-color: #FFC30650;
        }
        .colored {
            background-color: $color-grey-lighter;
        }
    }
    .faq-table {
        table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
        }
    }
    // the real height of the image
    $imgQuestion-01_height: 148px;
    .imgQuestion-01 {
        width: 100%;
        height: $imgQuestion-01_height;
        margin-bottom: $space-sm;
        background-image: url('/assets/images/imgQuestion01.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
    $imgQuestion-02_height: 155px;
    .imgQuestion-02 {
        width: 100%;
        height: $imgQuestion-02_height;
        margin-bottom: $space-sm;
        background-image: url('/assets/images/imgQuestion02.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.slide-container {
    font-family: $ff-regular;
    font-size: $fs-md;
    line-height: calc(1.5* #{$fs-md});

    * {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    h1, h2, h3, h4, h5, b, strong {
        font-family: $ff-bold;
    }
}

.user-box-wrapper{
    display: flex;
    align-items: center;
    .avatar{
        width: $avatar-width-default;
        height: $avatar-width-default;
        border-radius: 50%;
        border: 2px solid $color-black;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: $space-xs;
    }
    .user-info{
        p{
            margin-bottom: 0;
        }
        .name{
            font-family: $ff-regular;
            color: $color-black;
        }
        .info{
            font-family: $ff-bold;
            font-size: $fs-xs;
            color: $color-grey;
        }
    }
}

.white-box{
    background-color: $color-white;
    border-radius: 4px;
    padding: $space-xs $space-sm;
}

.incident-status{
    flex:1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-grey;
    background-color: $color-white;
    border-radius: $space-md $space-sm $space-sm $space-md;
    position: relative;
    font-size: $fs-sm;
    .pb-ball{
        left: 0;
        top: 0;
    }
    span.completed{
        font-family: $ff-bold;
        color: $color-black;
    }
}

mat-paginator.mat-mdc-paginator{
    background: transparent;
}

.fixed-box{
    position: fixed;
    z-index: 1001;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
}

.show-desktop{
    display: block !important;
}
.show-mobile{
    display: none !important;
}
.reverse{
    transform: rotate(180deg);
}
.animate{
    transition: all ease-in-out 0.3s;
}
.no-results{
    display: flex;
    flex-direction: column;
    align-items: center;
    span{
        color: $color-grey;
    }
}

.incident-question .fv-select-wrapper .fv-select-options {
    &.risk-incidents-options {
        width: $eval-risk-indicents-options-width !important;
    }
}

@media (max-width: 768px) {
    .show-desktop{
        display: none !important;
    }
    .show-mobile{
        display: flex !important;
    }
    .ferro-logo-big-wrapper{
        width: 100%;
        img{
            max-width: 100%;
            width: 60%;
        }
    }
    .container.pt-deskt-lg{
        padding-top:0;
    }
    .desktop-futball-field{
        background: none;
        overflow: inherit;
    }
    app-top-menu{
        position: relative;
        background-color: inherit;
        z-index: inherit;
    }
    .two-cols-desktop{
        display: inherit;
        .column{
            flex: none;
        }
    }
    .futball-field{
        background-image: url('/assets/images/futball-field.png');
        padding-bottom: $space-lg;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: auto;
        &.field{
            background-image: url('/assets/images/campo.jpg');
        }
    }
    .mbl-wrapper-strech{
        padding-left: $space-md;
        padding-right: $space-md;
    }
}

@media (max-width: 575px) {
    .gradient{
        background: linear-gradient(0deg, #36363625 0%, rgba(255,255,255,1) 80%, #36363663 100%);
    }
}

@media (min-width: 769px) {

}